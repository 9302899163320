<template>
  <fieldset>
    <DocFieldsZuEgrnTechPlan
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields-bottom>
        <MultiSelectField
          :modelValue="modelValue.oks"
          @update:modelValue="update('oks', $event, v$.oks)"
          :options="vectors"
          @reload="fetchWithDelay"
          :itemValidationRule="itemValidationRule"
          data-qa="capitalObjects"
          keyProp="externalId"
        >
          <label class="label">Объекты капитального строительства</label>
          <template #add>Добавить</template>
        </MultiSelectField>
      </template>
    </DocFieldsZuEgrnTechPlan>
  </fieldset>
</template>

<script>
import DocFieldsZuEgrnTechPlan from "./DocFieldsZuEgrnTechPlan.vue";
import DocTechPlan from "../../../models/documents/docTechPlan";
import { toRefs } from "vue";
import { helpers } from "@vuelidate/validators";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import { useVectorOks } from "../../../hooks/vector";
import useValidator from "./validator";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocTechPlan(),
    },
    documentTypeId:{
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: { DocFieldsZuEgrnTechPlan, MultiSelectField },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);

    const { vectors, fetchWithDelay } = useVectorOks();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let rules = {
    };

    let itemValidationRule = {
      externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
        return value !== null && value["externalId"] !== undefined
      })
    }

    const v$ = useValidator(data, rules);

    return { data, update, v$, vectors, fetchWithDelay, itemValidationRule };
  },
};
</script>