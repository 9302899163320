<template>
  <DocFields
    :modelValue="data"
    :document-type-id="documentTypeId"
    :document-reg-num="documentRegNum"
    :validation-rules="basicValidationRules"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #approval-authority-field>
      <SubjectField
        :modelValue="modelValue.engineer"
        @update:modelValue="update('engineer', $event, v$.engineer)"
        :errors="v$.engineer.$errors"
        mode="Person"
        data-qa="cadastralEngineer"
      >
        <template #label>
          <label class="label" for="registration-request-person-who-sent">
            Кадастровый инженер
          </label>
        </template>
      </SubjectField>
    </template>
    <template #additional-fields>
      <slot name="additional-fields"></slot>
    </template>
    <template #additional-fields-bottom>
      <MultiSelectField
        :modelValue="modelValue.zu"
        @update:modelValue="updateVector($event, v$.zu)"
        :options="vectors"
        @reload="fetchWithDelay"
        :errors="v$.$error ? v$.zu.$errors : []"
        :itemValidationRule="itemValidationRule"
        keyProp="externalId"
      >
        <label class="label label--required">Земельные участки</label>
        <template #add>Добавить</template>
      </MultiSelectField>
      <slot name="additional-fields-bottom"></slot>
    </template>
  </DocFields>
</template>

<script>
import MultiSelectField from "../../basic/MultiSelectField";
import SubjectField from "@/components/basic/form/SubjectField";
import DocFields from "./DocFields";

import { useVectorLandPlotEgrn } from "../../../hooks/vector";
import { toRefs } from "vue";
import { helpers, maxLength, minValue, required } from "@vuelidate/validators";
import useValidator from "./validator";
import subjectValidators from '../../../models/subjectValidators';

export default {
  emits: ["update:modelValue", "update:vector"],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    documentTypeId: {
      type: Number,
      required: true,
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: { DocFields, MultiSelectField, SubjectField },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);

    const { vectors, fetchWithDelay } = useVectorLandPlotEgrn();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    const basicValidationRules = {
      docNum: {
        maxLength: helpers.withMessage(
          "Количество символов не должно превышать 20",
          maxLength(20)
        ),
        required: helpers.withMessage("Заполните поле", required),
      },
      docDate: {
        minValue: helpers.withMessage(
          "Дата должна быть позднее 01.01.1970",
          minValue(new Date(1970, 0, 1))
        ),
        required: helpers.withMessage("Заполните поле", required),
      },
      docName: {
        maxLength: helpers.withMessage(
          "Количество символов не должно превышать 500",
          maxLength(500)
        ),
      },
      note: {
        maxLength: helpers.withMessage(
          "Количество символов не должно превышать 4000",
          maxLength(4000)
        ),
      },
    };
    let rules = {
      zu: {
        required: helpers.withMessage("Заполните поле", required),
      },
      engineer: {...subjectValidators},
    };

    let itemValidationRule = {
      externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
        return value !== null && value["externalId"] !== undefined
      })
    }

    const v$ = useValidator(data, rules);

    function updateVector(value, field) {
      update("zu", value, field);
      emit("update:vector", value);
    }

    return {
      data,
      update,
      updateVector,
      vectors,
      fetchWithDelay,
      basicValidationRules,
      v$,
      itemValidationRule
    };
  },
};
</script>